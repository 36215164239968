import 'bootstrap';
import '@hotwired/turbo-rails';
import { render } from 'timeago.js';
import { FetchRequest } from '@rails/request.js';

import '../assets/javascripts/lib/chosen.jquery';
import '../assets/javascripts/fragments';

const initTimeago = () => {
  const nodes = document.querySelectorAll('.timeago');
  if (!nodes.length) {
    return;
  }
  render(nodes);
};

const initChosen = () => {
  const chosenSelects = document.querySelectorAll('.chosen-select');

  chosenSelects.forEach((chosenSelect) => {
    if (chosenSelect.dataset.remotePath) {
      const request = new FetchRequest('get', chosenSelect.dataset.remotePath);

      request
        .perform()
        .then((response) => {
          if (response.ok) {
            return response.json;
          } else {
            throw new Error('Something went wrong!');
          }
        })
        .then((data) => {
          const selectedOptions = [].slice
            .call(chosenSelect.options)
            .flatMap((option) => {
              if (!option.selected) {
                return null;
              }
              return parseInt(option.value, 10);
            });

          // Delete all options from the select
          chosenSelect.innerHTML = '';

          data.forEach((tag) => {
            const option = new Option(
              tag.body,
              tag.id,
              selectedOptions.includes(tag.id),
              selectedOptions.includes(tag.id),
            );
            chosenSelect.add(option);
          });

          jQuery(chosenSelect).trigger('chosen:updated');
        });
    }
  });

  jQuery('.chosen-select').chosen({
    persistent_create_option: true,
    create_option: function (term) {
      const request = new FetchRequest('post', admin_tags_path, {
        body: JSON.stringify({ tag: { body: term } }),
      });

      const chosen = this;

      request
        .perform()
        .then((response) => {
          if (response.ok) {
            return response.json;
          } else {
            throw new Error('Something went wrong!');
          }
        })
        .then((value) => {
          // Prevent adding the same option multiple times.
          if (
            chosen.results_data
              .filter((e) => e.selected)
              .map((e) => e.value)
              .includes(`${value.id}`)
          ) {
            chosen.form_field_jq.trigger('chosen:updated');
            chosen.form_field_jq.trigger('change');
            chosen.search_field.trigger('focus');
            return;
          }

          chosen.append_option({
            value: value.id,
            text: value.body,
          });
        });
    },
    skip_no_results: true,
  });
};

const initPage = () => {
  initTimeago();
  initChosen();
};

document.addEventListener('turbo:load', initPage);

document.addEventListener('show.bs.modal', (event) => {
  const href = event.relatedTarget.href;

  if (!/#/.test(href) && href) {
    // prevent modal cache by explicitly loading the url
    const target = document.querySelector(event.relatedTarget.dataset.bsTarget);
    const content = target.querySelector('.modal-content');
    content.innerHTML = '<div class="modal-body">Loading...</div>';

    const request = new FetchRequest('get', href);

    request
      .perform()
      .then((response) => {
        if (response.ok) {
          return response.html;
        } else {
          throw new Error('Something went wrong!');
        }
      })
      .then((html) => {
        content.innerHTML = html;
      });
  }
});

document.addEventListener('turbo:before-prefetch', (event) => {
  if (event.target.dataset.bsToggle) {
    event.preventDefault();
  }
});
