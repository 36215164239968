import Sortable from 'sortablejs';
import { post } from '@rails/request.js';

function getCurrentPositions(list) {
  const positions = {};
  [].slice.call(list.children).forEach(function (child, index) {
    positions[index] = child.getAttribute('data-id');
  });
  return positions;
}

let dropArea = document.getElementById('fragment-drop-area');

function initDropArea() {
  document.addEventListener('dragenter', (event) => {
    event.preventDefault();
    event.stopPropagation();

    // When the mouse enters the window, relatedTarget is null
    if (event.relatedTarget === null) {
      document.body.classList.add('dragging');
    }
  });

  document.addEventListener('dragover', (event) => {
    event.preventDefault();
    event.stopPropagation();

    dropArea.classList.toggle('dragover', dropArea.contains(event.target));
  });

  document.addEventListener('dragleave', (event) => {
    event.preventDefault();
    event.stopPropagation();

    // When the mouse leaves the window, relatedTarget is null
    if (!event.relatedTarget) {
      document.body.classList.remove('dragging');
    }
  });

  document.addEventListener('drop', async (event) => {
    event.preventDefault();
    event.stopPropagation();

    dropArea.classList.remove('dragover');
    document.body.classList.remove('dragging');

    if (!dropArea.contains(event.target)) {
      return;
    }

    const url = dropArea.dataset.url;
    const files = [];

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...event.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          console.log(`… file[${i}].name = ${file.name}`);

          files.push(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...event.dataTransfer.files].forEach((file, i) => {
        console.log(`… file[${i}].name = ${file.name}`);

        files.push(file);
      });
    }

    if (files.length === 0) {
      return;
    }

    await processFiles(url, files, files.length);
  });
}

// Process files sequentially to ensure order is preserved
async function processFiles(url, files, fileCount) {
  const file = files.shift();
  const progress = document.getElementById('fragments-upload-progress');
  progress.hidden = false;

  if (file) {
    progress.querySelector('span').textContent = `Uploading ${file.name}…`;
    const bar = progress.querySelector('.progress-bar');
    bar.style.width = `${((fileCount - files.length) / fileCount) * 100}%`;
    bar.textContent = `${fileCount - files.length} / ${fileCount}`;

    await postImageFragment(url, file);
    await processFiles(url, files, fileCount);
  } else {
    progress.textContent = '';
    progress.hidden = true;
  }
}

function fileNameToTitle(fileName) {
  // Remove file extension and replace underscores with spaces
  return fileName.replace(/\.[^/.]+$/, '').replace(/_/g, ' ');
}

async function postImageFragment(url, file) {
  const formData = new FormData();
  formData.set('fragment[fragmentable_type]', 'ImageFragment');
  formData.set('fragment[fragmentable_attributes][file]', file);
  formData.set(
    'fragment[fragmentable_attributes][title]',
    fileNameToTitle(file.name),
  );
  formData.append('fragment[targets][]', 'web');
  formData.append('fragment[targets][]', 'app');

  const response = await post(url, { body: formData });

  // This triggers the turbo-frame to reload
  document.getElementById('fragments_table').src = response.response.url;
}

function initSortableFragments() {
  const list = document.getElementById('fragment-list');

  if (list) {
    let positions = getCurrentPositions(list);
    Sortable.create(list, {
      handle: '.fragment',
      onUpdate: async (event) => {
        const response = await post(
          move_admin_fragment_path.replace(
            ':id',
            event.item.getAttribute('data-id'),
          ),
          {
            body: JSON.stringify({
              target: positions[event.newIndex],
            }),
          },
        );

        if (!response.ok) {
          alert('Something went wrong!');
          window.location.reload();
        }

        positions = getCurrentPositions(list);
      },
    });
  }
}

function init() {
  initSortableFragments();
  initDropArea();
}

function update() {
  initSortableFragments();
  dropArea = document.getElementById('fragment-drop-area');
}

window.addEventListener('turbo:load', init);
document.addEventListener('turbo:frame-load', update);
